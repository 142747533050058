import React from 'react'
import styled from 'styled-components'

const Icon  = styled.span`
	& svg {
		& path:nth-child(1) {
    	transition: fill 0.2s ease-in-out;
    	transition-delay: 0.2s;
	  }
	  & path:nth-child(2) {
    	transition: fill 0.3s ease-in-out;
	  }
	  & .hover {
	  	& path:nth-child(1) {
		    fill: #0059b2;
	  	}
	  	& path:nth-child(2) {
		    fill: #0059b2;
	  	}
	  }
	}
`

const PlayIcon = (props) => (
	<Icon>
		<svg xmlns="http://www.w3.org/2000/svg" width="65" height="65.121" viewBox="0 0 65 65.121">
		  <g  id="Group_6948" data-name="Group 6948" transform="translate(-1335 -925.879)">
		    <g className={props.fill==="yes"?"hover":"none"} id="Group_6778" data-name="Group 6778">
		      <path id="Path_19083" data-name="Path 19083" d="M158.179,157.135a1.858,1.858,0,0,1-.964-.272,2.053,2.053,0,0,1-.964-1.76V127.025a2.052,2.052,0,0,1,.962-1.757,1.835,1.835,0,0,1,1.929,0L182.285,139.3a2.085,2.085,0,0,1,0,3.519l-23.143,14.046A1.858,1.858,0,0,1,158.179,157.135Zm1.929-26.6v21.05l17.35-10.531Z" transform="translate(1201.062 817.256)" fill="#dedede"/>
		      <path id="Path_19084" data-name="Path 19084" d="M32.5,65A32.5,32.5,0,1,1,65,32.5,32.537,32.537,0,0,1,32.5,65Zm0-60.937A28.438,28.438,0,1,0,60.937,32.5,28.47,28.47,0,0,0,32.5,4.063Z" transform="translate(1335 926)" fill="#dedede"/>
		    </g>
		    <path id="Path_19085" data-name="Path 19085" d="M826.5-17423.131l6.06.574" transform="translate(541 18351)" fill="none" stroke="#0059b2" />
		  </g>
		</svg>
	</Icon>
)

export default PlayIcon