import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import { Container, Section } from "../../components/SectionTags"
import ArrowIcon from "../../components/Icons/ArrowIcon"
import BackArrowIcon from "../../components/Icons/BackArrowIcon"
import BreakpointUp from "../../components/Media/BreakpointUp"

const TabWrap = styled.div`
  width: fit-content;
  border: 1px solid #dedede;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  background: white;
  border-radius: 8px;
  display: flex;
  position: relative;
  height: 40px;
  top: -20px;
  ${BreakpointUp.md`		
    height: 60px;
    top: -30px;
  `}
`
const TabItem = styled.div`
  background: ${(props) => props.bg};
  border-radius: 8px;
  color: ${(props) => props.color};
  box-shadow: ${(props) => props.boxShadow};  
  height: 100%;
  display: flex;
  align-items: center;
  font-family: 'UniNeueBold', sans-serif;
  cursor: pointer;
  font-size: 14px;
  line-height: 28px;
  padding: 0 9px;
  font-weight: 600;
  ${BreakpointUp.sm`		
    padding: 0 15px;
    font-weight: 800;
  `}
  ${BreakpointUp.md`		
    font-size: 18px;
    line-height: 28px;
  `}
  ${BreakpointUp.xl`		
    padding: 0 30px;
  `}
`
const PartnerWrap = styled.div`
  display: ${(props) => props.display};
  width: 100%;
`
const PartnerItem = styled.div`
  position: relative;
  background: white;
  border: 1px solid #dedede;
  border-radius: 8px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
  height: 242px;
  margin: 30px 15px;
  display:flex;
  align-items:center;
  justify-content:center;
  padding:0 15px;
`
const BrandWrap = styled.div`
  display: ${(props) => props.display};
  margin:0 ;
  position: relative;
`
const BrandItem = styled.div`
  position: relative;
  background: white;
  border: 1px solid #dedede;
  border-radius: 8px;
  box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
  text-align: center;
  height: 160px;
  margin: 30px 15px;
  display:flex;
  align-items:center;
  justify-content:center;
`


const SlickArrow = styled.div`
  text-align: center;
  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    background: #fff;
    border: 1px solid #dedede;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 38px;
    height: 38px;
    ${BreakpointUp.xl`		
			width: 48px;
			height: 48px;
		`}
    ${BreakpointUp.xxl`		
			width: 58px;
			height: 58px;
		`}
		& svg {
      transition: transform ease 0.2s;
    }
  }
`
const RightArrow = styled.div`
  right: -10px;
	& :hover {
    & svg {
      transform: translateX(3px);
    }
  }
`
const LeftArrow = styled.div`
  left: -10px;
	& :hover {
    & svg {
      transform: translateX(-3px);
    }
  }
`


function SampleNextArrow(props) {
  const { className, style } = props
  return <div className={className} style={{ ...style, display: "none" }} />
}

function SamplePrevArrow(props) {
  const { className, style } = props
  return <div className={className} style={{ ...style, display: "none" }} />
}

class PartnerSecion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isPartner: true,
      isBrand: false,
    }
    this.showPartner = this.showPartner.bind(this)
    this.showBrand = this.showBrand.bind(this)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }
  showPartner() {
    this.setState({
      isPartner: true,
      isBrand: false,
    })
  }
  showBrand() {
    this.setState({
      isPartner: false,
      isBrand: true,
    })
  }
  next() {
    this.slider.slickNext()
  }
  previous() {
    this.slider.slickPrev()
  }
  render() {
    const { isPartner, isBrand } = this.state
    const { partners, brands } = this.props
    const brandSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 1,
      autoplaySpeed: 2000,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            centerMode: false,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            centerMode: false,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            centerMode: false,
          },
        },
        {
          breakpoint: 567,
          settings: {
            slidesToShow: 1,
            centerMode: false,
          },
        },
      ],
    }
    const partnerSettings = {
      infinite: true,      
      slidesToShow: 3,
      slidesToScroll: 1,      
      arrows: false,
      autoplay: true,
      autoplaySpeed: 2000,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,            
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,            
          },
        },
      ],
    }
    return (
      <Section xpt="0" xpb="50px" mpt="0" mpb="30px" pt="0" pb="10px" bgColor="#f5f5f5">
         <Container>
          <TabWrap>
            <TabItem
              bg={isPartner ? "#0059B2" : "white"}
              color={isPartner ? "white" : "rgba(0, 0, 0, 0.4)"}
              boxShadow={
                isPartner ? "0 15px 25px rgba(28, 141, 255, 0.06)" : "none"
              }
              onClick={this.showPartner}
            >
              Our Partners
            </TabItem>
            <TabItem
              bg={isBrand ? "#0059B2" : "white"}
              color={isBrand ? "white" : "rgba(0, 0, 0, 0.4)"}
              boxShadow={
                isBrand ? "0 15px 25px rgba(28, 141, 255, 0.06)" : "none"
              }
              onClick={this.showBrand}
            >
              Brands We Repair
            </TabItem>
          </TabWrap>
        </Container>
        <Container>
          <PartnerWrap display={isPartner ? "block" : "none"}>
            <Slider {...partnerSettings}>
              {partners.edges.map((item, i) => {
                return (
                  <div>
                    <PartnerItem key={i}>
                      <GatsbyImage
                        image={getImage(item.node.logo)}
                        alt={item.node.name}
                      />
                    </PartnerItem>
                  </div>
                )
              })}
            </Slider>
          </PartnerWrap>       
          <BrandWrap display={isBrand ? "block" : "none"}>
            <SlickArrow>
              <LeftArrow onClick={this.previous} className="slick-arrow">
                <BackArrowIcon fill="#FAB702" />
              </LeftArrow>
              <RightArrow onClick={this.next} className="slick-arrow">
                <ArrowIcon fill="#FAB702" />
              </RightArrow>
            </SlickArrow>
            <Slider {...brandSettings} ref={(c) => (this.slider = c)}>
              {brands.edges.map((item, i) => {
                return (
                  <div>
                    <BrandItem key={i}>
                      <GatsbyImage
                        image={getImage(item.node.image)}
                        alt={item.node.title}
                      />
                    </BrandItem>
                  </div>
                )
              })}
            </Slider>
          </BrandWrap>
          <p>All product names, trademarks, brands and logos used on this site are the property of their respective owners. The depiction, description or sale of products featuring these names, trademarks, brands and logos is for identification purposes only and is not intended to indicate any affiliation with or authorization by any rights holder.</p>
        </Container>
      </Section>
    )
  }
}

export default PartnerSecion
