import React from 'react'
import styled from 'styled-components'

const Icon  = styled.span`
	& svg {
		& rect:nth-child(1) {
    	transition: fill 0.1s ease-in-out;
    	transition-delay: 0.1s;
	  }
	 	& rect:nth-child(2) {
    	transition: fill 0.2s ease-in-out;
    	transition-delay: 0.2s;
	  }
	  & rect:nth-child(3) {
    	transition: fill 0.3s ease-in-out;
    	transition-delay: 0.3s;
	  }
	  & rect:nth-child(4) {
    	transition: fill 0.4s ease-in-out;
    	transition-delay: 0.4s;
	  }
	  & rect:nth-child(5) {
    	transition: fill 0.5s ease-in-out;
    	transition-delay: 0.5s;
	  }
	  & rect:nth-child(6) {
    	transition: fill 0.6s ease-in-out;
	  }
	  & .hover {
	  	& rect:nth-child(1) {
		    fill: #0059b2;
	  	}
	  	& rect:nth-child(2) {
		    fill: #0059b2;
	  	}
	  	& rect:nth-child(3) {
		    fill: #0059b2;
	  	}
	  	& rect:nth-child(4) {
		    fill: #0059b2;
	  	}
	  	& rect:nth-child(5) {
		    fill: #0059b2;
	  	}
	  	& rect:nth-child(6) {
		    fill: #0059b2;
	  	}
	  }
	}
`

const QuoteIcon = (props)=> (
	<Icon>
		<svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65">
		  <g className={props.fill==="yes"?"hover":"none"} id="Group_6165" data-name="Group 6165" transform="translate(-479 -799)">
		    <rect id="Rectangle_2546" data-name="Rectangle 2546" width="65" height="4" transform="translate(479 811)" fill="#dedede"/>
		    <rect id="Rectangle_2547" data-name="Rectangle 2547" width="65" height="4" transform="translate(479 823)" fill="#dedede"/>
		    <rect id="Rectangle_2548" data-name="Rectangle 2548" width="65" height="4" transform="translate(479 836)" fill="#dedede"/>
		    <rect id="Rectangle_2549" data-name="Rectangle 2549" width="65" height="4" transform="translate(479 848)" fill="#dedede"/>
		    <rect id="Rectangle_2550" data-name="Rectangle 2550" width="40" height="4" transform="translate(479 860)" fill="#dedede"/>
		    <rect id="Rectangle_2552" data-name="Rectangle 2552" width="65" height="4" transform="translate(479 799)" fill="#dedede"/>
		    <rect id="Rectangle_2553" data-name="Rectangle 2553" width="4" height="4" transform="translate(479 799)" fill="#0059b2"/>
		  </g>
		</svg>
	</Icon>
)

export default QuoteIcon