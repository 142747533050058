import React from "react"
import styled from "styled-components"
import CheckIcon from "../../components/Icons/CheckIcon"
import Bg from "../../components/Image/IndustryHeroBg"
import { Background, Container, SectionTitle } from "../../components/SectionTags"
import { ProcessWrap, FirstProcess, ProcessNumber, OtherProcess } from "../../components/ProcessStyle"
import BreakpointUp from "../../components/Media/BreakpointUp"

const Wrap = styled.div`
  position: relative;
  padding: 40px 0;
  ${BreakpointUp.md`
		padding:60px 0;
	`}
  ${BreakpointUp.lg`
		padding:60px 0 90px;
	`}
	${BreakpointUp.xl`
		padding:100px 0;
	`}
	${BreakpointUp.xxl`
		padding:140px 0;
	`}
	& label {
    display: block;
  }
  

  & h2 {
    max-width: 768px;
    width: 100%;
    color: #000;
    margin-bottom: 20px;
    ${BreakpointUp.lg`		
			margin-bottom:30px;
		`}
    ${BreakpointUp.xl`		
			margin-bottom:60px;
		`}
  }
`

class ProcessSection extends React.Component {
  render() {
    const { data } = this.props
    return (
      <Wrap>
        <Background right="0" ts="rotateY(180deg)" height="600px">
          <Bg />
        </Background>
        <Container>
          {/* <label>{data.label}</label> */}
          <SectionTitle>Our Repair Process</SectionTitle>
          <ProcessWrap>
            {data.features.map((item, i) => {
              if (i === 0) {
                return (
                  <FirstProcess key={i}>
                    <strong className="h3">{item.process}</strong>
                    <p>{item.description}</p>
                    <ProcessNumber borderColor="rgba(250, 183, 2, 0.2)">
                      {i + 1}
                    </ProcessNumber>
                  </FirstProcess>
                )
              } else {
                return (
                  <OtherProcess key={i}>
                    <strong className="h3">{item.process}</strong>
                    <p>{item.description}</p>
                    <ProcessNumber borderColor="rgba(250, 183, 2, 0.2)">
                      {i + 1}
                    </ProcessNumber>
                  </OtherProcess>
                )
              }
            })}
            <CheckIcon fill="#fab702" />
          </ProcessWrap>
        </Container>
      </Wrap>
    )
  }
}

export default ProcessSection
