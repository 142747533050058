import React, { Component } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Slider from "react-slick"
import BreakpointUp from "../Media/BreakpointUp"

const SlickSlider = styled.div`
  position: relative;
  ${BreakpointUp.xxl`
    width: 1350px;
  `}
  .slick-thumb {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
    width: auto;
    left: 0;
    text-align: left;
  }

  .slick-thumb li {
    width: auto;
    height: auto;
    display: block;
    margin: 10px 0;
    ${BreakpointUp.sm`
      margin:20px 0;  
		`}
    ${BreakpointUp.xl`
      margin:30px 0;  
		`}
  }

  .slick-thumb li:nth-child(1) {
    ${BreakpointUp.lg`      
      margin-left: -20px;
    `}
  }
  .slick-thumb li:nth-child(2n) {
    ${BreakpointUp.lg`
      margin-left: -20px;
		`}
  }

  .slick-thumb li a {
    display: inline-block;
    background: white;
    border-radius: 50px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    color: #000;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
    transition: all ease-in-out 0.3s;
    font-family: 'UniNeueBold', sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
    padding: 0px 15px;
    ${BreakpointUp.sm`
      font-size: 14px;
      line-height: 36px;
      padding: 0px 20px;
    `}
  }
  .slick-thumb li a:hover {
    background: #fab702;
    border-color: #fab702;
    color: #fff;
  }
  .slick-thumb li.slick-active a {
    background: #fab702;
    border-color: #fab702;
    color: #fff;
  }

  .slick-slide a {
    width: 100%;
    max-height: 900px;
    object-fit: cover;
  }
`
const SlideImage = styled.div`
  padding: 10px;
  .gatsby-image-wrapper {
    border-radius: 15px;
    width:100%;
  }

`

export default class ServicesCarousel extends Component {
  render() {
    const { data } = this.props
    const settings = {
      customPaging: function (i) {
        return <a href={`/${data.edges[i].node.url}`}>{data.edges[i].node.serviceName}</a>
      },
      dots: true,
      dotsClass: "slick-dots slick-thumb",
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      swipeToSlide: true,
      autoplay: true,
      autoplaySpeed: 2000,
      arrows: false,
      responsive: [
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }
    return (
      <SlickSlider>
        <Slider {...settings}>
          {data.edges.map((item, i) => {
            return (
              <SlideImage key={i}>
                <GatsbyImage
                  image={getImage(item.node.image)}
                  alt={item.node.serviceName}
                />
              </SlideImage>
            )
          })}
        </Slider>
      </SlickSlider>
    )
  }
}