import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Hamburger from "../../components/Icons/Hamburger"
import QuoteIcon from "../../components/Icons/QuoteIcon"
import CallIcon from "../../components/Icons/CallIcon"
import PlayIcon from "../../components/Icons/PlayIcon"
import Menu from "../../components/Menu"
//import AlgoliaSearch from "../../components/AlgoliaSearch"
import Search from "../../components/Search"
import ItemHover from "../../components/ItemHover"
import ServicesCarousel from "../../components/ServicesCarousel"
import PrimaryButton from "../../components/Button/PrimaryButton"
import BreakpointDown from "../../components/Media/BreakpointDown"
import BreakpointUp from "../../components/Media/BreakpointUp"
import {Container} from "../../components/SectionTags"
import Logo from "../../images/logo.svg"
import BgSvg from '../../images/aboveheader-bg.svg';

const Wrap = styled.div`
  padding: 60px 0 30px;
  background: #f5f5f5;
  @media (min-width: 1025px) {
    padding: 50px 0 0;
  }
`
const Background = styled.div`
  position: absolute;
  display: none;
  height: 300px;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;

  @media(min-width: 768px) {
    display: block;
  }

  img {
    width: 100%;
    min-width: 1000px;
    transform: translateY(-50%);

    @media(min-width: 1024px) {
      transform: translateY(-50%);
    }
  }
`
const HamburgerMenu = styled.span`
  float: right;
  margin-top: 20px;
  &:hover {
    cursor: pointer;
  }
`
const HeroSection = styled.div`
  ${BreakpointUp.xxl`
    padding-left: calc((100% - 1350px) / 2);
    overflow:hidden;
	`}
`
const HeroContent = styled.div`
  display: flex;
  flex-wrap:wrap;
  align-items: center;
  margin: 30px -15px;
  ${BreakpointUp.xl`
		margin: 60px -30px 130px;
	`}
  ${BreakpointUp.xxl`
		margin: 90px -30px 200px;
	`}
`
const HeroSearch = styled.div`
  position: relative;
  width: 100%;
  padding:0 15px;
  ${BreakpointUp.lg`
    padding:0 30px;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  `}
  label {
    font-weight: 400;
    ${BreakpointDown.md`
			font-size: 14px;
  	  line-height: 24px;
		`}
  }
  h1{
    max-width:530px;
    width:100%;
  }
`
const HeroCarousel = styled.div`
  position: relative;
  width: 100%;
  padding:0 15px;
  ${BreakpointUp.lg`
    padding:0 30px;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  `}
`
// const SearchWrap = styled.div`
//   max-width: 360px;
//   width:100%;
// `

// const SearchCategorySelect = styled.select`
//   background: white;
//   border: 0;
//   border-radius: 50px;
//   width: 120px;
//   font-size: 14px;
//   padding: 10px 3px 10px 10px;
// `

const HeaderTop = styled.div`
  position: relative;
  display: flex;
  ${BreakpointUp.xl`
		height: 110px;
	`}
  ${BreakpointDown.lg`  
		white-space: nowrap;
		overflow-x: auto;
		margin:0 -5px;
		> span{
			margin:0 5px;
		}
	`}
`

const Box = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  background: #ffffff;
  border: 1px solid #dedede;
  height: 234px;
  transition: transform 0.5s;
  padding: 30px;
  ${BreakpointUp.xl`
		margin-top: -34px;
		padding: 40px;
	`}
  ${BreakpointUp.xxl`
		padding: 60px 60px 40px 60px;
	`}

	&:hover {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    ${BreakpointUp.xl`
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
			transform: translateY(-90px);
		`}
  }

  & button,
  & a {
    display: block;
    width: 100%;
    .btn {
      width: 100%;
    }
  }
`
const BoxItem = styled.div``
const BoxHeading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  ${BreakpointUp.xxl`
		margin-bottom:20px;
	`}
  .text {
    + .icon {
      margin-left: 15px;
    }
  }
`
const BoxTitle = styled.div`
  font-weight: 700;
  font-family: 'UniNeueBold', sans-serif;
  color: #000;
  font-size: 18px;
  line-height: 28px;
  display: block;
  ${BreakpointDown.lg`
		width: 190px;
		white-space: normal;
	`}
  ${BreakpointUp.xl`
		font-size: 20px;
		line-height: 28px;
	`}
`

class AboveHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpenMenu: false,
      searchCategory: 'all',
    }
    this.openMenu = this.openMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
    //this.setSearchCategory = this.setSearchCategory.bind(this);
  }

  openMenu() {
    this.setState({
      isOpenMenu: true,
    })
    document.body.style.height = "100vh"
  }
  // setSearchCategory(e) {
  //   console.log(e.target.value);
  //   this.setState({
  //     searchCategory: e.target.value,
  //   })
  // }
  closeMenu() {
    this.setState({
      isOpenMenu: false,
    })
    document.body.style.height = "unset"
  }

  render() {
    const { data, serviceData } = this.props
    const { isOpenMenu, searchCategory } = this.state
    return (
      <Wrap>
        <Background>
          <img src={BgSvg} />
        </Background>
        <div className="container tablet-mobile-hidden">
          <img src={Logo} alt="AES" />
          <HamburgerMenu onClick={this.openMenu}>
            <ItemHover>
              {(hoverItem) => (
                <Hamburger fill={hoverItem ? "#fab702" : "#0059B2"} />
              )}
            </ItemHover>
          </HamburgerMenu>
        </div>
        <Menu display={isOpenMenu ? "flex" : "none"} onClose={this.closeMenu} />
        <HeroSection>
          <Container maxWidth="100%">
            <HeroContent>
              <HeroSearch>
                <label>{data.label}</label>
                <h1>{data.title}</h1>
                {/* <SearchWrap>
                  <SearchCategorySelect onChange={(e) => this.setSearchCategory(e)}>
                    <option value="all">Our Services</option>
                    <option value="Electronic Repair">Electronic Repair</option>
                    <option value="Mechanical Repair">Mechanical Repair</option>
                    <option value="Servo Repair">Servo Repair</option>
                    <option value="Hydraulic Repair">Hydraulic Repair</option>
                    <option value="Kiosk & Printer Repair">Kiosk & Printer Repair</option>
                  </SearchCategorySelect>
                </SearchWrap> */}
                <Search category={searchCategory} />
              </HeroSearch>
              <HeroCarousel>
                <ServicesCarousel data={serviceData} />
              </HeroCarousel>
            </HeroContent>
          </Container>
        </HeroSection>
        <div className="container">
          <HeaderTop>
            <ItemHover>
              {(hoverItem) => (
                <Box>
                  <BoxItem>
                    <BoxHeading>
                      <BoxTitle className="text">
                        Estimate Your Repair Cost! Request a Quote!
                      </BoxTitle>
                      <span className="icon">
                        <QuoteIcon fill={hoverItem ? "yes" : "no"} />
                      </span>
                    </BoxHeading>
                    <button onClick={this.props.openRequest}>
                      <PrimaryButton text="Request a Quote" />
                    </button>
                  </BoxItem>
                </Box>
              )}
            </ItemHover>
            <ItemHover>
              {(hoverItem) => (
                <Box>
                  <BoxItem>
                    <BoxHeading>
                      <BoxTitle className="text">
                        Need Emergency Repairs? Call Us Now!
                      </BoxTitle>
                      <span className="icon">
                        <CallIcon fill={hoverItem ? "yes" : "no"} />
                      </span>
                    </BoxHeading>
                    <a href="tel:8663861001">
                      <PrimaryButton text="Call Us Now" />
                    </a>
                  </BoxItem>
                </Box>
              )}
            </ItemHover>
            <ItemHover>
              {(hoverItem) => (
                <Box>
                  <BoxItem>
                    <BoxHeading>
                      <BoxTitle className="text">Explore More About AES Services!</BoxTitle>
                      <span className="icon"><PlayIcon fill={hoverItem ? "yes" : "no"} /></span>
                    </BoxHeading>
                    <Link to="/about"><PrimaryButton text="Explore Services" /></Link>
                  </BoxItem>
                </Box>
              )}
            </ItemHover>
          </HeaderTop>
        </div>
      </Wrap>
    )
  }
}

export default AboveHeader
