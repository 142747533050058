import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import BreakpointUp from "../Media/BreakpointUp"
import Hamburger from "../Icons/Hamburger"
import VirticalArrowIcon from "../Icons/VirticalArrowIcon"
import Menu from "../Menu"
import ItemHover from "../ItemHover"
import ElectronicMenu from "./ElectronicMenu"
import HydraulicMenu from "./HydraulicMenu"
import ImagingMenu from "./ImagingMenu"
import MechanicalMenu from "./MechanicalMenu"
import ServoMenu from "./ServoMenu"

import WhiteLogo from "../../images/white-logo.svg"
// import Markquee from "../Marquee"

const MainHeader = styled.div`
  position: relative;
  background: #0059b2;
  z-index: 100;
  @media (max-width: 1024px) {
    width: 100%;
  }
`
const MenuGrid = styled.div`
  position: relative;
  display: grid;
  align-items: center;
  border-left: 2px solid rgba(255, 255, 255, 0.1);
  border-right: 2px solid rgba(255, 255, 255, 0.1);
  grid-template-columns: repeat(7, auto);
  grid-column-gap: 2px;
  @media (max-width: 1024px) {
    width: 100%;
    border: 0px;
    grid-template-columns: repeat(2, auto);
    & span {
      display: none;
    }
  }
`
const Logo = styled.div`
  text-align: center;
  padding: 0 15px;
  ${BreakpointUp.xxl`		
    padding:0 20px
	`}
  @media(max-width: 1024px) {
    text-align: left;
    width: 84px;
    padding: 0px;
  }
  & a {
    display: flex;
    align-items: center;
  }
`
const DropMenu = styled.div`
  position: relative;
  color: white;
  padding: 30px 20px !important;
  text-align: center;
  font-weight: 700;
  font-family: 'UniNeueBold', sans-serif;
  border-left: 2px solid rgba(255, 255, 255, 0.1);
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  line-height: 24px;
  @media (min-width: 1280px) {
    font-size: 16px;
    line-height: 30px;
  }
  & a {
    color: white;
  }
  & svg {
    vertical-align: top;
    margin-left: 5px;
  }
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 1024px) {
    display: none;
  }
`
const BurgerMenu = styled.div`
  border-left: 2px solid rgba(255, 255, 255, 0.1);
  text-align: center;
  display: flex;
  align-items: center;
  padding: 30px 15px !important;
  ${BreakpointUp.xxl`		
    padding: 30px 20px !important;
	`}
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 1024px) {
    text-align: right;
    border: 0px;
    padding: 19px 0px !important;
  }
  & svg {
    height: 22px;
  }
`
class StickHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpenMenu: false,
    }
    this.openMenu = this.openMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
  }

  openMenu() {
    this.setState({
      isOpenMenu: true,
    })
    document.body.style.height = "100vh"
  }
  closeMenu() {
    this.setState({
      isOpenMenu: false,
    })
    document.body.style.height = "unset"
  }
  componentDidMount() {
    document.body.style.height = "unset"
  }
  render() {
    const { isOpenMenu } = this.state
    return (
      <MainHeader className="sticky-inner">
        <div className="container">
          <MenuGrid>
            <Logo>
              <Link to="/">
                <img src={WhiteLogo} alt="AES" />
              </Link>
            </Logo>
            <ItemHover>
              {(hoverItem) => (
                <DropMenu>
                  Electronic Repair{" "}
                  <VirticalArrowIcon fill="rgba(255, 255, 255, 0.5)" />
                  <ElectronicMenu display={hoverItem ? "block" : "none"} />
                </DropMenu>
              )}
            </ItemHover>
            <ItemHover>
              {(hoverItem) => (
                <DropMenu>
                  Mechanical Repair{" "}
                  <VirticalArrowIcon fill="rgba(255, 255, 255, 0.5)" />
                  <MechanicalMenu display={hoverItem ? "block" : "none"} />
                </DropMenu>
              )}
            </ItemHover>
            <ItemHover>
              {(hoverItem) => (
                <DropMenu>
                  Servo Repair{" "}
                  <VirticalArrowIcon fill="rgba(255, 255, 255, 0.5)" />
                  <ServoMenu display={hoverItem ? "block" : "none"} />
                </DropMenu>
              )}
            </ItemHover>
            <ItemHover>
              {(hoverItem) => (
                <DropMenu>
                  Hydraulic Repair{" "}
                  <VirticalArrowIcon fill="rgba(255, 255, 255, 0.5)" />
                  <HydraulicMenu display={hoverItem ? "block" : "none"} />
                </DropMenu>
              )}
            </ItemHover>
            <ItemHover>
              {(hoverItem) => (
                <DropMenu>
                  Kiosk & Printer Repair{" "}
                  <VirticalArrowIcon fill="rgba(255, 255, 255, 0.5)" />
                  <ImagingMenu display={hoverItem ? "block" : "none"} />
                </DropMenu>
              )}
            </ItemHover>
            <BurgerMenu onClick={this.openMenu}>
              <Hamburger fill="white" />
            </BurgerMenu>
          </MenuGrid>
        </div>
          {/* <Markquee /> */}
        <Menu
          top="110px"
          display={isOpenMenu ? "block" : "none"}
          onClose={this.closeMenu}
        />
      </MainHeader>
    )
  }
}

export default StickHeader
