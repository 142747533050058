import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import ServiceDropDown from "../../components/ServiceDropDown"
import VirticalArrowIcon from "../../components/Icons/VirticalArrowIcon"
import ItemHover from "../../components/ItemHover"
import { dropdown } from "../../components/Animation"
import { MarkdownContent, SectionTitle } from "../../components/SectionTags"
import BreakpointUp from "../../components/Media/BreakpointUp"

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const LeftPannel = styled.div`
  position: relative;
  width: 100%;
  background: #004c98;
  color: #fff;
  padding: 40px 30px;
  ${BreakpointUp.md`		
		padding:60px;
	`}
  ${BreakpointUp.xl`
		padding-top:90px;
		padding-bottom:90px;
		padding-right: 90px;
		width: 60%;
		padding-right: calc(50vw - 510px);
	`}
	${BreakpointUp.xxl`
		padding-top:125px;
		padding-bottom:125px;
		padding-right: 160px;
		width: 60%;
		padding-left: calc(50vw - 675px);	
	`}
	& h2 {
    color: #fff;
    margin-bottom: 5px;
  }
  & p {
    color: #fff;
  }
  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Whychoose = styled.div`
  position:relative;
`
const RightPannel = styled.div`
  position: relative;
  width: 100%;
  background: #0059b2;
  padding: 40px 30px;
  ${BreakpointUp.md`		
		padding:60px;
	`}
  ${BreakpointUp.xl`
		padding-top:90px;
		padding-bottom:90px;
		padding-left: 90px;
		width: 40%;
		padding-right: calc(50vw - 510px);
	`}
	${BreakpointUp.xxl`
		padding-top:125px;
		padding-bottom:125px;
		padding-left: 115px;
		width: 40%;
		padding-right: calc(50vw - 675px);
	`}

  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const ChooseItem = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 10px 0;
  .icon {
    width: 60px;
    height: 60px;
    min-width: 60px;
    border:2px solid rgba(255,255,255,0.3);
    border-radius:50%;
    display:flex;
    align-items:center;
    justify-content:center;
    + .text {
      margin-left: 20px;
    }
  }
  .text {
    font-family: 'UniNeueBold', sans-serif;
    font-weight: 700;
    color: #fff;
    font-size: 18px;
    line-height: 26px;
    ${BreakpointUp.md`
			font-size: 22px;
			line-height: 28px;
		`}
    ${BreakpointUp.xxl`
			font-size: 24px;
			line-height: 34px;
		`}
  }
`
const Divider = styled.div`
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
`

const DropWrap = styled.div`
  position: relative;
  z-index: 50;
  width: 100%;
  max-width:430px;  
`

const DropButton = styled.div`
  position: relative;
  width: 100%;
  color: #fff;
  font-size: 20px;
  line-height: 72px;
  font-weight: 700;
  font-family: 'UniNeueBold', sans-serif;
  border: 1px solid rgba(165, 205, 240, 0.6);
  border-radius: 5px;
  padding: 0 30px;
  cursor: pointer;
  background: ${(props) => props.bg};
  color: #fff;
  animation: ${dropdown} ease 0.5s;
  z-index: 20;
  & svg {
    float: right;
    margin-top: 25px;
  }
`

class ChooseSection extends React.Component {
  render() {
    const { data } = this.props
    return (
      <Wrapper>
        <LeftPannel>
          <StaticImage src="../../images/choose-left.png" alt="AES" />
          <Whychoose>
            <h2>{data.title}</h2>
            <MarkdownContent
              dangerouslySetInnerHTML={{
                __html: data.description.childMarkdownRemark.html,
              }}
            />
            {data.features.map((item, i) => {
              return (
                <ChooseItem key={i}>
                  <span className="icon">
                    <img src={item.icon} alt={item.feature} />{" "}
                  </span>
                  <span className="text">{item.feature}</span>
                </ChooseItem>
              )
            })}
            <Divider />
          </Whychoose>
        </LeftPannel>
        <RightPannel>
          <StaticImage src="../../images/choose-right.png" alt="AES" />
          <SectionTitle color="#fff">{data.secondTitle}</SectionTitle>
          <DropWrap>
            <ItemHover>
              {(hoverItem) => (
                <div>
                  <DropButton bg={hoverItem ? "#FAB702" : "#0059B2"}>
                    Help Me With <VirticalArrowIcon fill="white" />
                  </DropButton>
                  <ServiceDropDown
                    width="100%"
                    mWidth="100%"
                    display={hoverItem ? "block" : "none"}
                  />
                </div>
              )}
            </ItemHover>
          </DropWrap>
        </RightPannel>
      </Wrapper>
    )
  }
}

export default ChooseSection
