import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import ArrowIcon from "../../components/Icons/ArrowIcon"
import VideoBlock from "../../components/VideoBlock"
import MVVideoModal from "../../components/MVVideoModal"
import BreakpointUp from "../../components/Media/BreakpointUp"
import PrimaryLinkButton from "../../components/Button/PrimaryLinkButton"

const Wrap = styled.div`
  padding: 40px 0;
  ${BreakpointUp.md`
		padding:60px 0;
	`}
  ${BreakpointUp.xl`
		padding:100px 0;
	`}
	${BreakpointUp.xxl`
		padding:160px 0;
	`}
`
const ContentGrid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -15px;
  ${BreakpointUp.xl`	
		margin:0 -30px;
	`}
  ${BreakpointUp.xxl`	
		margin:0 -50px;
	`}

	& .btn {
    & svg {
      transition: transform ease 0.2s;
    }
    &:hover {
      & svg {
        transform: translateX(5px);
      }
    }
  }
`

const TextWrap = styled.div`
  position: relative;
  width: 100%;
  padding: 15px;
  ${BreakpointUp.lg`	
		max-width: 50%;
		flex: 0 0 50%;
	`}
  ${BreakpointUp.xl`	
		padding:0 30px;
	`}
	${BreakpointUp.xxl`	
		padding:0 50px;
	`}
`

const VideoWrap = styled.div`
  position: relative;
  width: 100%;
  padding: 15px;
  ${BreakpointUp.lg`	
		max-width: 50%;
		flex: 0 0 50%;
	`}
  ${BreakpointUp.xl`	
		padding:0 30px;
	`}
	${BreakpointUp.xxl`	
		padding:0 50px;
	`}
	& video {
    width: 100%;
  }
`

class AboutSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isVideoModalOpen: false,
      currentVideo: { src: "", type: "" },
    }
    this.handleVideo = this.handleVideo.bind(this)
    this.openVideoModal = this.openVideoModal.bind(this)
    this.closeVideoModal = this.closeVideoModal.bind(this)
  }
  playVideo() {
    this.refs.vidRef.play()
  }
  openVideoModal(url) {
    this.setState({
      currentVideo: {
        type: "video/youtube",
        src: url,
      },
      isVideoModalOpen: true,
    })
  }
  closeVideoModal() {
    this.setState({ isVideoModalOpen: false })
  }
  handleVideo(url) {
    this.openVideoModal(url)
  }
  render() {
    const { data } = this.props
    return (
      <Wrap>
        <MVVideoModal
          isOpen={this.state.isVideoModalOpen}
          autoPlay
          src={this.state.currentVideo.src}
          type={this.state.currentVideo.type}
          controls
          onClose={this.closeVideoModal}
        />
        <div className="container">
          <ContentGrid>
            <TextWrap>
              <label>{data.label}</label>
              <h2>{data.title}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.description.childMarkdownRemark.html,
                }}
              />
              <Link to="/about">
                <PrimaryLinkButton text={data.linkText} icon={<ArrowIcon />} />{" "}
              </Link>
            </TextWrap>
            <VideoWrap>
              <VideoBlock
                action={() => this.handleVideo(data.videoUrl)}
                video={data.videoUrl}
              />
            </VideoWrap>
          </ContentGrid>
        </div>
      </Wrap>
    )
  }
}

export default AboutSection
