import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Slider from "react-slick"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import ArrowIcon from "../../components/Icons/ArrowIcon"
import DotIcon from "../../components/Icons/DotIcon"
import BreakpointUp from "../../components/Media/BreakpointUp"
import PrimaryLinkButton from "../../components/Button/PrimaryLinkButton"
import { Section, SectionTitle, Container } from "../../components/SectionTags"

const CardGrid = styled.div`
  position: relative;
  height: 370px;
  display: grid;
  margin: 0 0 30px;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  @media (max-width: 992px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    height: auto;
  }
  @media (max-width: 767px) {
    display: none;
  }
`
const SliderWrap = styled.div`
  display: none;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    display: block;
  }
`
const FirstArticle = styled.div`
  position: relative;
  border-radius: 8px;
  padding: 20px 0;
  cursor: pointer;
  height: 370px;
  & .gatsby-image-wrapper {
    position: absolute !important;
    object-fit: cover;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    z-index: 1;
  }
  & label {
    position: relative;
    margin-left: 20px;
    padding: 5px 10px;
    border-radius: 8px;
    color: white;
    background: black;
    font-size: 12px;
    font-family: 'UniNeueBold', sans-serif;
    font-weight: 700;
    line-height: 20px;
    z-index: 2;
  }
`
const CardBottom = styled.div`
  position: absolute;
  bottom: 0px;
  padding: 20px;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  height: 144px;
  z-index: 3;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  align-items: flex-end;
`
const Description = styled.div`
font-family: 'UniNeueBold', sans-serif;
  font-weight: 700;
  font-style: italic;
  color: white;
  width: calc(100% - 230px);
  ${BreakpointUp.xxl`		
		font-size: 18px;
		line-height: 30px;
	`}
`
const WriterWrap = styled.div`
  position: relative;
  width: ${(props) => props.width};
  display: ${(props) => props.display};
  margin-left: ${(props) => props.ml};
  & svg {
    margin: 0 5px;
  }
`
WriterWrap.defaultProps = {
  display: "block",
  ml: "0",
  width: "230px",
}

const Avatar = styled.div`
  position: relative;
  height: 50px;
  text-align: right;
  margin-bottom: 5px;
  & .gatsby-image-wrapper {
    position: relative !important;
    float: right;
    object-fit: contain;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    border: 2px solid #fab702;
  }
`
const Writer = styled.div`
  text-align: right;
  & strong,
  & span {
    font-family: 'UniNeueBold', sans-serif;
    font-weight: 800;
    display: block;
  }
  & strong {
    font-size: 16px;
    line-height: 22px;
    color: #fab702;
  }
  & span {
    font-size: 14px;
    line-height: 24px;
    margin-top: 4px;
    color: #666;
  }
`
const ArticleCard = styled.div`
  margin-top: ${(props) => props.mt};
  height: 172px;
  display: flex;
  border: 1px solid #dedede;
  background: #ffffff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`
const ArticleImage = styled.div`
  width: 35%;
  height: 100%;
  border-radius: 8px;
  & .gatsby-image-wrapper {
    height: 100%;
    border-radius: 8px;
  }
`
const ArticleSummary = styled.div`
  width: 65%;
  padding: 20px;
  padding-left: 28px;
  margin-left: -8px;
  & p {
    font-family: 'UniNeueBold', sans-serif;
    font-weight: 700;
    font-style: italic;
    color: #333333;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    ${BreakpointUp.xxl`
			font-size: 18px;
			line-height: 30px;
		`}
  }
`
const SecondCardAvatar = styled.div`
  width: 50px;
  height: 50px;
  margin-right: 14px;
  & .gatsby-image-wrapper {
    position: relative;
    z-index: 3;
    object-fit: contain;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    border: 2px solid #fab702;
  }
  @media (max-width: 600px) {
    margin-right: 10px;
    margin-top: 2px;
  }
`
const SecondCardWriter = styled.div`
  & strong,
  & span {
    font-family: 'UniNeueBold', sans-serif;
    display: block;
    font-weight: 800;
  }
  & strong {
    font-size: 16px;
    line-height: 22px;
    color: #fab702;
  }
  & span {
    font-size: 14px;
    line-height: 24px;
    color: #666666;
    margin-top: 4px;
  }
  @media (max-width: 600px) {
    & strong {
      font-size: 14px !important;
    }
    & span {
      font-size: 12px !important;
      margin-top: 0;
    }
  }
`

const SliderArticleCard = styled.div`
  position: relative;
  & label {
    position: absolute;
    top: 10px;
    left: 10px;
    background: black;
    color: white;
    font-size: 12px;
    padding: 0px 10px;
    border-radius: 3px;
  }
  .gatsby-image-wrapper {
    height: 189px;
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`
const CardText = styled.div`
  padding: 14px 20px;
  background: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  & p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 10px;
  }
  .gatsby-image-wrapper {
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }
`
const ButtonToolbar = styled.div``

class ArticlesSection extends React.Component {
  render() {
    const { data, articles } = this.props
    // const temp = articles.edges[0].node.title
    //   .replace(/[^\w\s]+/gi, " ")
    //   .replace(/  +/g, " ")
    // const titleUrl = temp.split(" ").join("-").toLowerCase()
    // const temp1 = articles.edges[1].node.title
    //   .replace(/[^\w\s]+/gi, " ")
    //   .replace(/  +/g, " ")
    // const titleUrl1 = temp1.split(" ").join("-").toLowerCase()
    // const temp2 = articles.edges[2].node.title
    //   .replace(/[^\w\s]+/gi, " ")
    //   .replace(/  +/g, " ")
    // const titleUrl2 = temp2.split(" ").join("-").toLowerCase()
    const settings = {
      dots: false,
      swipeToSlide: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
    }

    return (
      <Section xpt="130px" xpb="130px" mpt="80px" mpb="80px" pt="60px" pb="60px" bgColor="#fff">
        <Container>
          <SectionTitle>{data.title}</SectionTitle>
          <CardGrid>
            <Link to={`/${articles.edges[0].node.url}`}>
              <FirstArticle>
                <GatsbyImage
                  image={getImage(articles.edges[0].node.thumbnail)}
                  alt="AES"
                />
                <label htmlFor="Featured">Featured</label>
                <CardBottom>
                  <Description>{articles.edges[0].node.title}</Description>
                  <WriterWrap>
                    <Avatar>
                      <GatsbyImage
                        image={getImage(articles.edges[0].node.authorImage)}
                        alt="AES"
                      />
                    </Avatar>
                    <Writer>
                      <strong>{articles.edges[0].node.authorName}</strong>
                      <span>
                        {articles.edges[0].node.authorRole}
                        <DotIcon fill="white" />
                        {articles.edges[0].node.postDate}
                      </span>
                    </Writer>
                  </WriterWrap>
                </CardBottom>
              </FirstArticle>
            </Link>
            <div>
              <Link to={`/${articles.edges[1].node.url}`}>
                <ArticleCard>
                  <ArticleImage>
                    <GatsbyImage
                      image={getImage(articles.edges[1].node.thumbnail)}
                      alt="AES"
                    />
                  </ArticleImage>

                  <ArticleSummary>
                    <p>{articles.edges[1].node.title}</p>
                    <WriterWrap display="flex" width="100%" ml="-45px">
                      <SecondCardAvatar>
                        <GatsbyImage
                          image={getImage(articles.edges[1].node.authorImage)}
                          alt="AES"
                        />
                      </SecondCardAvatar>
                      <SecondCardWriter>
                        <strong>{articles.edges[1].node.authorName}</strong>
                        <span>
                          {articles.edges[1].node.authorRole} <DotIcon />
                          {articles.edges[1].node.postDate}
                        </span>
                      </SecondCardWriter>
                    </WriterWrap>
                  </ArticleSummary>
                </ArticleCard>
              </Link>
              <Link to={`/${articles.edges[2].node.url}`}>
                <ArticleCard mt="30px">
                  <ArticleImage>
                    <GatsbyImage
                      image={getImage(articles.edges[2].node.thumbnail)}
                      alt="AES"
                    />
                  </ArticleImage>
                  <ArticleSummary>
                    <p>{articles.edges[2].node.title}</p>
                    <WriterWrap display="flex" width="100%" ml="-45px">
                      <SecondCardAvatar>
                        <GatsbyImage
                          image={getImage(articles.edges[2].node.authorImage)}
                          alt="AES"
                        />
                      </SecondCardAvatar>
                      <SecondCardWriter>
                        <strong>{articles.edges[2].node.authorName}</strong>
                        <span>
                          {articles.edges[2].node.authorRole} <DotIcon />
                          {articles.edges[2].node.postDate}
                        </span>
                      </SecondCardWriter>
                    </WriterWrap>
                  </ArticleSummary>
                </ArticleCard>
              </Link>
            </div>
          </CardGrid>
          <SliderWrap>
            <Slider {...settings}>
              {articles.edges.map((item, i) => {
                const flag = item.node.title
                  .replace(/[^\w\s]+/gi, " ")
                  .replace(/  +/g, " ")
                const Url = flag.split(" ").join("-").toLowerCase()
                return (
                  <Link key={i} to={`/${item.node.url}`}>
                    <SliderArticleCard>
                      <GatsbyImage
                        image={getImage(articles.edges[0].node.thumbnail)}
                        alt="AES"
                      />
                      <label htmlFor="Featured">Featured</label>
                      <CardText>
                        <p>{item.node.title}</p>
                        <WriterWrap display="flex" width="100%">
                          <SecondCardAvatar>
                            <GatsbyImage
                              image={getImage(
                                articles.edges[2].node.authorImage
                              )}
                              alt="AES"
                            />
                          </SecondCardAvatar>
                          <SecondCardWriter>
                            <strong>{articles.edges[2].node.authorName}</strong>
                            <span>
                              {articles.edges[2].node.authorRole} <DotIcon />
                              {articles.edges[2].node.postDate}
                            </span>
                          </SecondCardWriter>
                        </WriterWrap>
                      </CardText>
                    </SliderArticleCard>
                  </Link>
                )
              })}
            </Slider>
          </SliderWrap>
          <ButtonToolbar>
            <Link to="/articles">
              <PrimaryLinkButton text={data.linkText} icon={<ArrowIcon />} />
            </Link>
          </ButtonToolbar>
        </Container>
      </Section>
    )
  }
}

export default ArticlesSection
