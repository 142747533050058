import React, {useState} from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image"

const SearchBox = styled.form`
	display: flex;
	align-items: center;
	width:100%;	
    background-color: #fff;
    box-shadow: rgb(0 0 0 / 10%) 0px 10px 30px;
    border: 1px solid rgb(222, 222, 222);
    border-radius: 50px;	
	margin-bottom:30px;
	max-width:360px;
	width:100%;	
	&.input-icon {
		&.right{
			> .form-control {
				padding: 10px 65px 10px 15px;		
			}
		}
	}
	.icon {
		background: #fab702;
		border-radius: 50px;
		padding: 10px 15px;
		cursor: pointer;
	}
`

const Search = ({ category }) => {
	const [name, setName] = useState("");

	const handleSubmit = (evt) => {
    evt.preventDefault();
    navigate(`/search?term=${name}`, { state: { searchKey: name, searchCategory: category } });
  };
  return (
  	<SearchBox onSubmit={handleSubmit} className='input-icon right'>
		<input
		type="text"
		id="headerSearchInput"
		value={name}
		placeholder="Search for your need ..."
		onChange={e => setName(e.target.value)}
		required
		className='form-control'
		/>
	    
		<button type="submit" className='icon'><StaticImage src="../../images/search-icon.png" placeholder="search-icon" alt="AES"/></button>
	  </SearchBox>
	)
}

export default Search;