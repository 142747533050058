import React from 'react'
import styled from 'styled-components'

const Icon  = styled.span`
	& svg {
		& path:nth-child(1) {
    	transition: fill 0.3s ease-in-out;
	  }
	  & .riffle {
    	transition: fill 0.2s ease-in-out;
    	transition-delay: 0.3s;
	  }
	  & .hover {
	  	& path:nth-child(1) {
		    fill: #0059b2;
	  	}
	  	& .riffle {
		    fill: #0059b2;
	  	}
	  }
	}
`

const CallIcon = (props) => (
<Icon>
	<svg xmlns="http://www.w3.org/2000/svg" width="64.128" height="65" viewBox="0 0 64.128 65">
	  <g className={props.fill==="yes"?"hover":"none"} id="Group_6816" data-name="Group 6816" transform="translate(-887 -925)">
	    <g id="contact" transform="translate(792.667 925)">
	      <path id="Path_19078" data-name="Path 19078" d="M109.6,0c-4.37.173-8.34,3.822-11.179,10.277-2.637,6-4.089,13.887-4.089,22.222,0,8.435,1.482,16.394,4.174,22.412C101.417,61.417,105.471,65,109.922,65h10.849V43.382h-8.711a56.356,56.356,0,0,1-1-10.786A56.213,56.213,0,0,1,112.1,21.618h8.674V0H109.677Zm7.361,3.807v14h-7.916l-.342,1.474A59.03,59.03,0,0,0,107.248,32.6a59.194,59.194,0,0,0,1.412,13.114L109,47.191h7.965v14h-7.04c-2.818,0-5.711-2.856-7.938-7.835C99.506,47.817,98.142,40.41,98.142,32.5c0-7.819,1.338-15.166,3.767-20.688,2.18-4.956,5.025-7.87,7.809-8Z" fill="#dedede"/>
	    </g>
	    <rect id="Rectangle_2616" data-name="Rectangle 2616" width="3.7" height="4" transform="translate(909.7 925)" fill="#0059b2"/>
	    <path className="riffle" id="Union_37" data-name="Union 37" d="M568.456,18406.74l-1.658-1.658a1.174,1.174,0,0,1,0-1.658l1.658-1.658a31.905,31.905,0,0,0,0-44.967l-1.658-1.654a1.174,1.174,0,0,1,0-1.658l1.658-1.658a1.174,1.174,0,0,1,1.658,0l1.658,1.658a36.6,36.6,0,0,1,0,51.6l-1.658,1.658a1.172,1.172,0,0,1-1.658,0Zm-6.629-6.633-1.658-1.654a1.174,1.174,0,0,1,0-1.658l1.658-1.658a22.535,22.535,0,0,0,0-31.709l-1.658-1.658a1.169,1.169,0,0,1,0-1.654l1.658-1.658a1.174,1.174,0,0,1,1.658,0l1.658,1.658a27.224,27.224,0,0,1,0,38.338l-1.658,1.654a1.167,1.167,0,0,1-1.658,0Zm-6.629-6.625-1.658-1.658a1.174,1.174,0,0,1,0-1.658l1.658-1.658a13.157,13.157,0,0,0,0-18.451l-1.658-1.654a1.174,1.174,0,0,1,0-1.658l1.658-1.658a1.174,1.174,0,0,1,1.658,0l1.658,1.658a17.846,17.846,0,0,1,0,25.08l-1.658,1.658a1.172,1.172,0,0,1-1.658,0Zm-6.971-9.682a5.952,5.952,0,0,0,0-8.523l2.656-2.73a9.75,9.75,0,0,1,0,13.979Z" transform="translate(368.716 -17422.039)" fill="#dedede"/>
	  </g>
	</svg>
</Icon>
)

export default CallIcon