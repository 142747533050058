import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Slider from "react-slick"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import QuotationMark from "../../components/Icons/QuotationMark"
import ArrowIcon from "../../components/Icons/ArrowIcon"
import BackArrowIcon from "../../components/Icons/BackArrowIcon"
import BreakpointUp from "../../components/Media/BreakpointUp"
import PrimaryButton from "../../components/Button/PrimaryButton"

const Wrap = styled.div`
  position: relative;
  padding: 40px 0;
  ${BreakpointUp.md`
		padding:60px 0;
	`}
  ${BreakpointUp.xl`
		padding:100px 0;
	`}
	${BreakpointUp.xxl`
		padding:180px 0;
	`}
`

const Quotation = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 80px;
  ${BreakpointUp.lg`		
		height: 140px;
	`}
  ${BreakpointUp.xl`		
		height: 180px;
	`}
	${BreakpointUp.xxl`		
		height: 220px;
	`}
`
const ContentWrap = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 0 -15px;
  ${BreakpointUp.lg`		
		margin:0 -20px;
	`}
  ${BreakpointUp.xl`		
		margin:0 -40px;
	`}
	${BreakpointUp.xxl`		
		margin:0 -70px;
	`}
`

const SectionLeft = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  margin-bottom: 30px;
  ${BreakpointUp.lg`		
		padding:0 20px;
		flex: 0 0 50%;
		max-width: 50%;
		margin-bottom:0;
	`}
  ${BreakpointUp.xl`		
		padding:0 40px;
	`}
	${BreakpointUp.xxl`		
		padding:0 70px;
	`}
  h2{
    font-size: 16px;
    line-height: 28px;
    color: #666;
    font-weight: 600;
    font-family: "UniNeueBook", sans-serif;
    margin:0;
  }
  label{
    display:block;
    font-family: 'UniNeueBold', sans-serif;
    font-weight: 700;
    margin:0 0 15px; 
    color: #000;
    font-size: 24px;
    line-height: 32px;
    @media (min-width: 768px) {
      font-size: 32px;
      line-height: 44px;
    }
    @media (min-width: 992px) {
      font-size: 44px;
      line-height: 58px;
    }
  }
`

const SectionRight = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.lg`		
		padding:0 20px;
		flex: 0 0 50%;
		max-width: 50%;
	`}
  ${BreakpointUp.xl`		
		padding:0 40px;
	`}
	${BreakpointUp.xxl`		
		padding:0 70px;
	`}
`
const Testimonial = styled.div`
  position: relative;
  z-index: 10;
  background: white;
  border: 1px solid #dedede;
  border-radius: 8px;
  padding: 40px;
  ${BreakpointUp.xl`	
		padding: 60px;
	`}
  ${BreakpointUp.xxl`	
		padding: 60px 80px 80px;
	`}
	& p {
    font-style: italic;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 9;
    ${BreakpointUp.sm`		
			-webkit-line-clamp: 6;
		`}
    ${BreakpointUp.xl`		
			font-size: 18px;
			line-height: 34px;
		`}	
		${BreakpointUp.xxl`		
			font-size: 22px;
			line-height: 38px;
		`}
  }
`
const WriterWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 36px;
  @media (max-width: 480px) {
    margin-top: 10px;
  }
`
const Avatar = styled.div`
  width: 50px;
  height: 50px;
  & .gatsby-image-wrapper {
    object-fit: contain;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    border: 2px solid #fab702;
  }
`
const Writer = styled.div`
  margin-left: 17px;
  & strong,
  & span {
    display: block;
    font-family: 'UniNeueBold', sans-serif;
    font-weight: 700;
  }
  & strong {
    font-size: 16px;
    line-height: 22px;
    color: #fab702;
  }
  & span {
    font-size: 14px;
    line-height: 24px;
    margin-top: 4px;
    color: #666;
  }
`

const SlickArrow = styled.div`
  text-align: center;
  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    background: #fff;
    border: 1px solid #dedede;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 38px;
    height: 38px;
    ${BreakpointUp.xl`		
			width: 48px;
			height: 48px;
		`}
    ${BreakpointUp.xxl`		
			width: 58px;
			height: 58px;
		`}
		& svg {
      transition: transform ease 0.2s;
    }
  }
`
const RightArrow = styled.div`
  right: 0;
  ${BreakpointUp.xl`	
		right: 25px;
	`}
  ${BreakpointUp.xxl`	
		right: 40px;
	`}
	& :hover {
    & svg {
      transform: translateX(3px);
    }
  }
`
const LeftArrow = styled.div`
  left: 0;
  ${BreakpointUp.xl`	
		left: 25px;
	`}
  ${BreakpointUp.xxl`	
		left: 40px;
	`}
	& :hover {
    & svg {
      transform: translateX(-3px);
    }
  }
`

function SampleNextArrow(props) {
  const { className, style } = props
  return <div className={className} style={{ ...style, display: "none" }} />
}

function SamplePrevArrow(props) {
  const { className, style } = props
  return <div className={className} style={{ ...style, display: "none" }} />
}

class TestimonialSection extends React.Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }
  next() {
    this.slider.slickNext()
  }
  previous() {
    this.slider.slickPrev()
  }
  render() {
    const { data, testimonials } = this.props
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      adaptiveHeight: true,
    }
    return (
      <Wrap>
        <Quotation>
          <QuotationMark />
        </Quotation>
        <div className="container">
          <ContentWrap>
            <SectionLeft>
              <h2>{data.label}</h2>
              <label>{data.title}</label>
              <Link to="/review">
                <PrimaryButton text={data.linkText} />{" "}
              </Link>
            </SectionLeft>
            <SectionRight>
              <SlickArrow>
                <LeftArrow onClick={this.previous} className="slick-arrow">
                  <BackArrowIcon fill="#FAB702" />
                </LeftArrow>
                <RightArrow onClick={this.next} className="slick-arrow">
                  <ArrowIcon fill="#FAB702" />
                </RightArrow>
              </SlickArrow>

              <Slider {...settings} ref={(c) => (this.slider = c)}>
                {testimonials.edges.map((item, i) => {
                  return (
                    <Testimonial key={i}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.node.review.childMarkdownRemark.html,
                        }}
                      />
                      <WriterWrap>
                        <Avatar>
                          <GatsbyImage
                            image={getImage(item.node.customerImage)}
                            alt="AES"
                          />
                        </Avatar>
                        <Writer>
                          <strong>{item.node.customerName}</strong>
                          <span>{item.node.customerRole}</span>
                        </Writer>
                      </WriterWrap>
                    </Testimonial>
                  )
                })}
              </Slider>
            </SectionRight>
          </ContentWrap>
        </div>
      </Wrap>
    )
  }
}

export default TestimonialSection
