import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import ItemHover from "../../components/ItemHover"
import ArrowIcon from "../../components/Icons/ArrowIcon"
import BreakpointUp from "../../components/Media/BreakpointUp"
import BreakpointDown from "../../components/Media/BreakpointDown"
import { Container, Section } from "../../components/SectionTags"

const Label = styled.label`
  color: #666;
  font-size: 16px;
  line-height:28px;
  font-family: 'UniNeueBold', sans-serif;
  font-weight: 700;
`
const ContentWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  display: ${(props) => props.display};
  flex-direction: column-reverse;
  ${BreakpointUp.lg`
		flex-direction: inherit;
	`}
`
const ImageWrap = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`
    flex: 0 0 50%;
    max-width: 50%;
	`}
`
const TextWrap = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`
    flex: 0 0 50%;
    max-width: 50%;
	`}
  ${BreakpointUp.xl`
    padding-bottom:95px;
	`}
	& h2 {
    margin-bottom:10px;
    font-size: 24px;
    line-height: 32px;
    @media (min-width: 768px) {
      font-size: 32px;
      line-height: 44px;
    }
    @media (min-width: 1366px) {
      font-size: 44px;
      line-height: 58px;
    }
  }
  & p {
    margin-bottom: 30px;
    margin-top: 0;
    font-size:16px;
    line-height:28px;
  }
`

const TextScroll = styled.div`
  position: relative;
  width: 100%;
  @media (min-width: 992px) {
    overflow-y: auto;
    max-height: 270px;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.1);
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
`


const ServicesList = styled.div`
  margin:15px 0 30px;
  display: grid;
  grid-gap: 10px;
  ${BreakpointUp.md`
		grid-template-columns: repeat(2, 1fr);
	`}
`
const Service = styled.div`
  padding-left: 0 !important;
  font-family: 'UniNeueBold', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;

  & a {
    color: #000;
    display: flex;
    align-items: start;
    flex-wrap: nowrap;
    .text {
      display: inline-block;
      vertical-align: middle;
    }
    .icon {
      display: inline-block;
      vertical-align: middle;
      + .text {
        margin-left: 10px;
      }
      & svg {
        transition: transform ease 0.2s;
      }
    }
    &:hover {
      color: #0059b2;
      & svg {
        transform: translateX(5px);
      }
    }
  }
`
const ServicesWrap = styled.div`
  white-space: nowrap;
  overflow-x: auto;	
  padding-top: 30px;
  margin: 0 -2px;
  ${BreakpointUp.md`
		display: flex;
		align-items: center;
		justify-content: center;
	`}
	${BreakpointUp.xl`
		position: absolute;
		bottom:0;
		right: 0;
	`}
`
const CustomNav = styled.div`
  position: relative;
  width: 170px;
  height: 95px;
  background: ${(props) => props.bg};
  text-align: center;
  border-radius: 8px;
  margin: 0 2px;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
  ${BreakpointDown.md`
		display: inline-block;
	`}
  & .gatsby-image-wrapper {
    border-radius: 8px;
    position: relative;
    opacity: 0.2;
    z-index: 10;
    position: absolute !important;
    width: 100%;
    height: 100%;
    display: ${(props) => props.display};
  }
  &:hover {
    background-color: #0059b2;
    .gatsby-image-wrapper {
      display: none;
    }
  }
`
const NavText = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 12;
  width: 100%;
  color: white;
  opacity: ${(props) => props.opacity};
  font-family: 'UniNeueBold', sans-serif;
  font-weight: 800;
  font-size: 16px;
  line-height: 28px;
  padding: 10px;
  white-space: normal;
  ${BreakpointUp.xxl`		
		font-size:18px;
	`}
  &:hover {
    opacity: 1;
  }
`

class HeroSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentRepair: this.props.data.edges[0].node.serviceName,
    }
    this.renderRepair = this.renderRepair.bind(this)
    this.renderFeatures = this.renderFeatures.bind(this)
  }
  renderRepair(data) {
    this.setState({
      currentRepair: data,
    })
  }
  renderFeatures(data) {
    return data
      .filter((item) => item.featured === true)
      .map((element, index) => {
        return (
          <ItemHover key={index}>
            {(hoverItem) => (
              <Service key={index}>
                <Link to={`/${element.url}`}>
                  <span className="icon">
                    <ArrowIcon fill={hoverItem ? "#0059B2" : "#FAB702"} />
                  </span>
                  <span className="text">{element.name}</span>
                </Link>
              </Service>
            )}
          </ItemHover>
        )
      })
  }

  render() {
    const { data, label } = this.props
    const { currentRepair } = this.state

    return (
      <Section xpt="140px" xpb="230px" mpt="80px" mpb="80px" pt="40px" pb="40px">
        <Container>
          {data.edges.map((item, i) => {
            return (
              <ContentWrap
                display={
                  currentRepair === item.node.serviceName ? "flex" : "none"
                }
                key={i}
                mDisplay={
                  currentRepair === item.node.serviceName ? "block" : "none"
                }
              >
                <ImageWrap>
                  <GatsbyImage image={getImage(item.node.image)} alt="AES" />
                </ImageWrap>
                <TextWrap>
                  <Label>{label}</Label>
                  <h2 className="h2">{item.node.serviceName}</h2>
                  <TextScroll>
                    <p> {item.node.description.description}</p>
                    <ServicesList>
                      {this.renderFeatures(item.node.service_sub_repair)}
                    </ServicesList>
                  </TextScroll>
                </TextWrap>
              </ContentWrap>
            )
          })}
        </Container>
        <Container>
          <ServicesWrap>
            {data.edges.map((item, i) => {
              return (
                <CustomNav
                  key={i}
                  onClick={() => this.renderRepair(item.node.serviceName)}
                  bg={
                    currentRepair === item.node.serviceName
                      ? "rgba(0, 89, 178, 0.9)"
                      : "rgba(0, 89, 178, 0.7)"
                  }
                  display={
                    currentRepair === item.node.serviceName ? "none" : "block"
                  }
                  opacity={
                    currentRepair === item.node.serviceName ? "1" : "0.8"
                  }
                >
                  <GatsbyImage image={getImage(item.node.image)} alt="AES" />
                  <NavText>{item.node.serviceName}</NavText>
                </CustomNav>
              )
            })}
          </ServicesWrap>
        </Container>
      </Section>
    )
  }
}
export default HeroSection
