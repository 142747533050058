import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/Header"
import StickHeader from "../components/Header/StickHeader"
import CallToAction from "../components/CallToAction"
// import StickyFooter from "../components/Footer/StickyFooter"
import IndustriesCarousel from "../components/IndustriesCarousel"
import RequestQuote from "../components/RequestQuote"
import Marquee from "../components/Marquee"

import AboveHeader from "../sections/home/AboveHeader"
import HeroSection from "../sections/home/HeroSection"
import ChooseSection from "../sections/home/ChooseSection"
import ProcessSection from "../sections/home/ProcessSection"
import AboutSection from "../sections/home/AboutSection"
import PartnerSecion from "../sections/home/PartnerSecion"
import TestimonialSection from "../sections/home/TestimonialSection"
import ArticlesSection from "../sections/home/ArticlesSection"
// import VideosSection from "../sections/home/VideosSection"

const StickyHeaderWrap = styled.div`
  position: fixed;
  top: -120px;
  transform: ${(props) => props.transform};
  opacity: ${(props) => props.opacity};
  width: 100%;
  z-index: 120;
  transition: all ease 0.3s;
  .markquee{
    position:initial;
  }
`

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSticky: false,
      isStickyFooter: false,
      previousPos: 0,
      isOpenRequest: false,
    }
    this.handleScroll = this.handleScroll.bind(this)
    this.openRequestQuote = this.openRequestQuote.bind(this)
    this.closeRequestQuote = this.closeRequestQuote.bind(this)
  }
  openRequestQuote() {
    this.setState({
      isOpenRequest: true,
    })
    //document.body.style.height = "100vh"
    document.querySelector("body").classList.add("modal-open")
  }
  closeRequestQuote() {
    this.setState({
      isOpenRequest: false,
    })
    //document.body.style.height = "unset"
    document.querySelector("body").classList.remove("modal-open")
  }
  
  handleScroll(event) {
    if (this.headerRef) {
      const headerOffset = this.headerRef.getBoundingClientRect().top
      const offset = window.scrollY - this.state.previousPos
      this.setState({
        previousPos: window.scrollY,
      })
      if (headerOffset <= 0) {
        this.setState({
          isSticky: true,
        })
      } else {
        this.setState({
          isSticky: false,
        })
      }
      // if (headerOffset <= 0 && offset < 0) {
      //   this.setState({
      //     isSticky: true,
      //   })
      // } else {
      //   this.setState({
      //     isSticky: false,
      //   })
      // }
      // if (headerOffset <= 600 && offset > 100) {
      //   this.setState({
      //     isStickyFooter: true,
      //   })
      // } else {
      //   this.setState({
      //     isStickyFooter: false,
      //   })
      // }
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, { passive: true })
  }
  render() {
    const { data } = this.props
    const { isSticky, isOpenRequest } = this.state

    const aboveHeaderData = data.contentfulHomePage.aboveHeader
    const allServices = data.allContentfulService
    const heroLabel = data.contentfulHomePage.heroLabel
    const chooseSectionData = data.contentfulHomePage.chooseSection
    const processSectionData = data.contentfulHomePage.processSection
    const industrySectionTitle = data.contentfulHomePage.industrySectionTitle
    const industrySectionData = data.allContentfulIndustry
    const aboutSectionData = data.contentfulHomePage.aboutSection
    const partnerData = data.allContentfulPartner
    const brandData = data.allContentfulBrand
    const testimonialSectionData = data.contentfulHomePage.testimonialSection
    const testimonials = data.allContentfulTestimonial
    const ctaData = data.contentfulHomePage.ctaSection
    const articleSectionData = data.contentfulHomePage.articleSection
    const articles = data.allContentfulArticle
    // const videoSectionTitle = data.contentfulHomePage.videoSectionTitle
    // const videos = data.allContentfulVideo

    return (
      <Layout>
        <Seo
          title={data.contentfulHomePage.metaTitle}
          description={data.contentfulHomePage.metaDescription.metaDescription}
        />
        <StickyHeaderWrap
          transform={isSticky ? "translateY(110px)" : "translateY(0px)"}
        >
          <StickHeader />
        </StickyHeaderWrap>
        <RequestQuote
          display={isOpenRequest ? "block" : "none"}
          onClose={this.closeRequestQuote}
        />
        <AboveHeader
          data={aboveHeaderData}
          serviceData={allServices}
          openRequest={this.openRequestQuote}
        />
        <div className="sticky-wrapper" ref={(c) => (this.headerRef = c)}>
          <Header />
          
        </div>
        {/* <div className={`sticky-footer-wrapper${isStickyFooter ? ' sticky' : ' none-sticky'}`} >
          <StickyFooter openRequest={this.openRequestQuote} />
        </div> */}
        <HeroSection label={heroLabel} data={allServices} />
        <ChooseSection data={chooseSectionData} />
        <ProcessSection
          data={processSectionData}
          openRequest={this.openRequestQuote}
        />
        <IndustriesCarousel
          title={industrySectionTitle}
          data={industrySectionData}
        />
        <AboutSection data={aboutSectionData} />
        <PartnerSecion partners={partnerData} brands={brandData} />
        <TestimonialSection
          data={testimonialSectionData}
          testimonials={testimonials}
        />
        <CallToAction data={ctaData} />
        <ArticlesSection data={articleSectionData} articles={articles} />
        {/* <VideosSection title={videoSectionTitle} data={videos} /> */}
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageQuery {
    contentfulHomePage {
      metaTitle
      metaDescription {
        metaDescription
      }
      aboveHeader {
        title
        label
      }
      heroLabel
      chooseSection {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        features {
          feature
          icon
        }
        secondTitle
      }
      processSection {
        label
        features {
          process
          description
        }
      }
      industrySectionTitle
      aboutSection {
        label
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        linkText
        videoUrl
      }
      testimonialSection {
        label
        title
        linkText
      }
      ctaSection {
        label
        title
        linkText
        image {
          gatsbyImageData(quality: 100)
        }
      }
      articleSection {
        title
        linkText
      }
      videoSectionTitle
    }
    allContentfulService(sort: { fields: order }) {
      edges {
        node {
          url
          serviceName
          description {
            description
          }
          image {
            gatsbyImageData(quality: 80, width: 700, sizes: "700")
          }
          service_sub_repair {
            url
            name
            featured
          }
        }
      }
    }
    allContentfulIndustry(sort: { fields: order }) {
      edges {
        node {
          url
          name
          thumbnail {
            gatsbyImageData(quality: 100)
          }
          description {
            childMarkdownRemark {
              excerpt(pruneLength: 250)
            }
          }
          image {
            gatsbyImageData(quality: 100)
          }
          features {
            content
          }
        }
      }
    }
    allContentfulPartner(sort: { fields: order }) {
      edges {
        node {
          logo {
            gatsbyImageData(quality: 100)
          }
          url
          name
          logoWidth
        }
      }
    }
    allContentfulBrand(sort: { fields: order }) {
      edges {
        node {
          image {
            gatsbyImageData(quality: 100)
          }
          url
          title
          imageWidth
        }
      }
    }
    allContentfulTestimonial {
      edges {
        node {
          customerName
          customerRole
          review {
            childMarkdownRemark {
              html
            }
          }
          customerImage {
            gatsbyImageData(quality: 100)
          }
        }
      }
    }
    allContentfulArticle(sort: { fields: postDate, order: DESC }, limit: 3) {
      edges {
        node {
          url
          title
          thumbnail {
            gatsbyImageData(quality: 100)
          }
          authorName
          authorRole
          authorImage {
            gatsbyImageData(quality: 100)
          }
          postDate(fromNow: true)
        }
      }
    }
    allContentfulVideo {
      edges {
        node {
          title
          videoUrl
        }
      }
    }
  }
`
